$header_image_height: 90px
$header_image_width:  120px

@media print
	#header-print
		border-bottom: 1px dotted #ECE3E3
		margin-bottom: 10px
		.date
			position: absolute
			white-space: nowrap
			right: 5mm
			top: 0
		img
			margin-top: 10px
			width: 100%