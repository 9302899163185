.only-print
  display: none

@media print
  .only-print
    display: block
    visibility: visible
    *
      visibility: visible
  .hidden-print
    display: none !important

  // Força a quebra de página
  .page-break
    page-break-before: always
    page-break-after: always
    display: block
    position: relative

  // Não permite que um elemento quebre de página.
  // com isso o elemento irá iniciar na proxima página caso fosse quebrar.
  .nobreak
    page-break-inside: avoid
    break-inside: avoid
    display: inline-block
    &:before
      clear: both

  .print-hidden-overflow
    overflow-x: hidden !important
    overflow-y: hidden !important